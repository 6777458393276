import React from 'react'
import Layout from '../../components/Layout'
import {Location} from '@reach/router'
import Content from '../../components/Content'
export default () => (  
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                  <Content>
                  <h2>
                      More than an emergency button
                  </h2>
                    <p>
                        a complete 24/7 safety net
                    </p>
                    <p>
                        US Alarms Wellness offers a secure and cost-effective option for independent living with safety, security, and comfort.
                    </p>

                    <h2>
                        Activity Patterns
                    </h2>
                    <p>
                        Understand your family member’s activity pattern and get an alert if it’s out of the ordinary.
                    </p>

                    <h2>
                        Awareness
                    </h2>
                    <p>
                        Make sure your loved one is getting up and about.
                    </p>

                    <h2>
                        Alerts
                    </h2>
                    <p>
                        Be alerted if your loved one is wandering or leaving the house at odd hours
                    </p>.

                    <h2>
                        Security & Automation
                    </h2>
                    <p>
                        Simplify daily routines with automated temperature, light and security settings.
                    </p>

                    <h2>
                        Integrates Pendants
                    </h2>
                    <p>
                        Wellness can also be paired with Personal Emergency Response (PERS) pendants.
                    </p>

                    <h2>
                        Stay Safe
                    </h2>
                    <p>
                        US Alarms Wellness is coupled with the leading home security solution from US Alarms. Get additional peace of mind with 24/7 emergency response for intrusion, fire and medical emergencies and have persistent awareness of what’s going on at home.
                    </p>

                    <h2>
                        Peace of mind with monitoring & alerts
                    </h2>
                    <p>Family members and caregivers can monitor their loved-one’s activity, such as how much time is spent in bed, in a favorite chair or out of the house. And, with intelligent sensors to track and learn the home’s activities of daily living, Wellness can identify anomalies that may signify a problem.</p>
                    <p>More comprehensive than traditional Personal Emergency Response (PERS) wearable pendants, US Alarms Wellness is a safety net that provides a more complete view of a loved one’s well-being, and delivers automatic, real-time alerts. Unlike a PERS button, US Alarms Wellness does not rely on the individual to take an action or remember to keep the button with them. With additional information like inactivity alerts or unusual activity patterns your PERS system can be enhanced to provide a more comprehensive solution.</p>

                    <h2>
                        Enhance independence & comfort with automation
                    </h2>
                    <p>
                        Wellness can also simplify routine tasks, with seamless automation across the home’s critical systems. Easy to use rules and schedules allow you to automate lights, thermostats, locks and security – so your loved one doesn’t have to remember to turn lights on or off, or lock the door every night. Caregivers can even remotely check on thermostat settings and adjust the temperature, if needed, through a mobile app.
                    </p>
                  </Content>
                </Layout>
            )
        }
    </Location>
)